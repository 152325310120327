// React
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
// Components
import { Nav, Step } from '../partials/index';
import Explanations from '../Explanations/Explanations';
// Utils
import { perfume } from '../../utils/communication';
import GetTrad from "../../utils/GetTrad";
// CSS
import "./cognac.css"

function Step2(props) {
	const navigate = useNavigate();

	const { bottle } = useLocation().state;

	const language = useSelector((state) => state.language.current);

	const [explanations, setExplanations] = useState(0);

	const goNext = () => {
		//props.setTransition(1);

		setTimeout(() => {
			navigate('/cognac3', { state: { bottle: bottle } });
		}, 500);
	}

	useEffect(() => {
		props.setTransition(0);
	}, []);

	useEffect(() => {
		if (explanations === 0) {
			let video = document.getElementById('bottleVideo');

			video.currentTime = 0;
			video.style.display = "block";
			video.style.objectFit = "fill";
			video.play();

			video.onclick = () => {
				video.pause();
				props.setSmell({ time: 2, next: goNext });

				perfume(bottle.scent);
			}

			video.onended = () => {
				video.pause();
				props.setSmell({ time: 2, next: goNext });

				perfume(bottle.scent);
			}
		}
	}, [explanations]);

	let resize = () => {
		let videoContainerElem = document.getElementById('bottleVideoContainer');
		let videoElem = document.getElementById('bottleVideo');

		if (videoContainerElem && videoElem) {
			let width = videoContainerElem.offsetWidth;
			let height = videoContainerElem.offsetHeight;

			if (width / height >= 2736/1824) {
				videoElem.style.width = "100%";
				videoElem.style.height = "";

				videoElem.style.left = "0%";
				videoElem.style.top = "50%";
				videoElem.style.transform = ("translateY(-50%)");
			} else {
				videoElem.style.width = "";
				videoElem.style.height = "100%";

				videoElem.style.left = "50%";
				videoElem.style.top = "0%";
				videoElem.style.transform = ("translateX(-50%)");
			}
		}
	}

	useEffect(() => {
		resize();
	});

	useEffect(() => {
		window.addEventListener("resize", () => {
			resize();
		});
		return () => {
			window.addEventListener("resize", () => {
				resize();
			});
		}
	}, []);

	let dataExplanations = {
		time: 10,
		desc: [
			"explanationsCognac-desc1-0",
			"explanationsCognac-desc1-1"
		],
		obj: [
			(<div className="explanations-watch">
				<div className="explanations-watchImg"></div>
				<div className="explanations-watchContent">
					<GetTrad id="explanations-watch" lang={language} upperCase />
				</div>
			</div>),
			(<div className="explanations-and">{'&'}</div>),
			(<div className="explanations-smell">
				<div className="explanations-smellImg"></div>
				<div className="explanations-smellContent">
					<GetTrad id="explanations-smell" lang={language} upperCase />
				</div>
			</div>)
		],
		bottle: bottle.name
	};

	return (
		<div className="c-step2">
			<Nav setTransition={props.setTransition} />
			<Step step={1} />
			{/* {explanations > 0 && <Explanations step={2} setExplanations={setExplanations} data={dataExplanations} />} */}

			{bottle && bottle.bottleVideo && <div id="bottleVideoContainer" className="bottleVideoContainer"><video id={'bottleVideo'} className="bottleVideo" src={bottle.bottleVideo}> </video></div>}
			<div className="c-step2-skip"><div className='arrow'></div><div className="c-step2-skip-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

export default Step2;