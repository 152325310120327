import { useEffect, useState } from "react";
import Header from "../partials/Header";
import Nav from "../partials/Nav"
import Step from "../partials/Step";
import GetTrad from "../../utils/GetTrad";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { perfume } from '../../utils/communication';
import "./discover.css"

import { default as tastes } from "../../data/tastes"
import { default as bottleList } from "../../data/bottlesList"

function Discover(props) {
	const navigate = useNavigate();

	const { bottle } = useLocation().state;

	const language = useSelector((state) => state.language.current);

	const fire = (elem) => {

		let id = 0;
		for (let bottl of bottleList) {
			if (bottl.id === elem) {
				id = bottl.scent;
			}
		}

		let elemButton = document.getElementById('discover-smellButton-' + elem);
		let elemText = document.getElementById('discover-smellContent-' + elem);

		if (elemButton && elemText) {
			elemButton.style.backgroundColor = "white";
			elemText.style.color = "black";
		}

		if (id != 404) { // TODO !
			setTimeout(() => {
				props.setSmell({ time: 2, next: undefined });
				perfume(id);

				elemButton.style.backgroundColor = "";
				elemText.style.color = "";
			}, 200)
		}
	}

	const goEnd = () => {
		let elemButton = document.getElementById('discover-gift');
		let elemImg = document.getElementById('discover-giftImg');
		let elemText = document.getElementById('discover-giftContent');

		if (elemButton && elemImg && elemText) {
			elemButton.style.backgroundColor = "white";
			elemImg.style.filter = "invert(100%)";
			elemText.style.color = "black";
		}

		setTimeout(() => {
			props.setTransition(1);

			setTimeout(() => {
				navigate('/forfurther'/*, { state: { bottle: props.bottle } }*/);
			}, 500);
		}, 200)
	}

	useEffect(() => {
		props.setTransition(0);
	})

	const goDiscover = (value) => {
		props.setTransition(1);

		let bottleTmp = bottleList[0];
		for (let bottl of bottleList) {
			if (bottl.id === value) {
				bottleTmp = bottl;
			}
		}

		setTimeout(() => {
			navigate('/flavour3', { state: { bottle: bottleTmp } });
		}, 500);
	}

	return (
		<div className="discover">
			<Nav setTransition={props.setTransition} />
			<Step step={3} />
			<div className="discover-background" style={{ backgroundImage: bottle.bgImg }}>
				<div className="discover-partLeft">
					{/* <div className="discover-bottle" style={{ backgroundImage: bottle.bottleImg }}></div> */}
					<div id={"discover-smellButton-" + bottle.id} className="discover-smellButton" onClick={() => fire(bottle.id)}>
						<div id={"discover-smellContent-" + bottle.id} className="discover-smellContent">
							<GetTrad id="discover-smellAgain" lang={language} upperCase />
						</div>
					</div>
				</div>
				<div className="discover-partRight">
					<div className="discover-title">
						<div className="discover-title-content">
							<div className="discover-yourMatch"><GetTrad id="discover-match" lang={language} upperCase /></div>
							<div className="discover-bottleName">{bottle.name.toUpperCase()}</div>
						</div>
					</div>
					<div className="discover-content">
						<div className="discover-adj"><GetTrad id={bottle.adj} lang={language} upperCase /></div>
						<div className="discover-adjDesc"><GetTrad id={bottle.adj + 'Desc'} lang={language} /></div>

						<div className="discover-validationOptsValid" onClick={() => { goEnd() }}>
							<div className="discover-validationOptsValidContent">
								<GetTrad id="discover-gift" lang={language} upperCase data={[props.bottleName]} />
							</div>
						</div>

					</div>
					<div className="discover-experience">
						<div className="discover-adj">
							<GetTrad id="discover-partBottomRightText" lang={language} upperCase />
						</div>
						<div className="discover-adjDesc">
							<div className="discover-partBottomSameFlavourFamily" onClick={() => goDiscover(bottle.sameFlavourProfile)}>
								<div className="discover-partBottomSameFlavourFamilyImg" style={{ backgroundImage: `url('/assets/backgrounds/discovers/littleBottles/${bottle.sameFlavourProfile}.png')` }}></div>
								<div className="discover-partBottomSameFlavourFamilyText"><GetTrad id='discover-sameFlavourFamily' lang={language} /></div>
							</div>
							<div className="discover-partBottomSurpriseMe" onClick={() => goDiscover(bottle.discovery)}>
								<div className="discover-partBottomSurpriseMeImg" style={{ backgroundImage: `url('/assets/backgrounds/discovers/littleBottles/${bottle.discovery}.png')` }}></div>
								<div className="discover-partBottomSurpriseMeText"><GetTrad id='discover-surpriseMe' lang={language} /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Discover;