import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import GetTrad from "../../utils/GetTrad";
import "./step.css"

function Step(props) {

	return (
		<div className="step">
			<div className={'step-number ' + (props.step === 0 ? 'step-focus' : '')}>
				<div className='step-number-content'>01</div>
			</div>
			<div className={'step-number ' + (props.step === 1 ? 'step-focus' : '')}>
				<div className='step-number-content'>02</div>
			</div>
			<div className={'step-number ' + (props.step >= 2 ? 'step-focus' : '')}>
			<div className='step-number-content'>03</div>
			</div>
			<div className='step-delimit-container'>
				<div className='step-delimit-1'></div>
				<div className='step-delimit-2'></div>
			</div>
		</div>
	);
}

export default Step;