// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
// Components
import { Nav, Step } from '../partials/index';
import Explanations from '../Explanations/Explanations';
// Utils
import GetTrad from '../../utils/GetTrad';
// Data
import { default as flavourPath } from '../../data/flavourPath';
import { default as bottlesList } from '../../data/bottlesList';
// CSS
import './flavour.css'

function Step1(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const [flavour, setFlavour] = useState(null);
	const [bottles, setBottles] = useState(bottlesList);

	const [explanations, setExplanations] = useState(0);

	const [currentVideo, setCurrentVideo] = useState(null);
	const [currentVideoPlay, setCurrentVideoPlay] = useState(null);

	useEffect(() => {
		for (let bottle of bottles) {
			bottle.score = 0;
		}
	}, [])

	const play = (value) => {
		if (currentVideo !== null) {
			currentVideo.style.display = "none"
			currentVideo.pause();
			currentVideo.currentTime = 0;

			if (currentVideoPlay !== null) {
				currentVideoPlay.style.backgroundImage = "url('/assets/icons/replay.png')";
				currentVideoPlay.style.display = "block";
			}
		}

		let elem = document.getElementById(value + '_video');
		let elemPlay = document.getElementById(value + '_videoPlay');

		elem.currentTime = 0;
		elem.style.display = "block";
		elem.style.objectFit = "fill";
		elemPlay.style.display = "none";
		elemPlay.style.backgroundImage = "url('/assets/icons/replay.png')";
		elem.play();

		setCurrentVideo(elem);
		setCurrentVideoPlay(elemPlay);

		elem.onended = () => {
			elem.style.display = "none"
			elem.pause();
			elem.currentTime = 0;
			elemPlay.style.backgroundImage = "url('/assets/icons/replay.png')";
			elemPlay.style.display = "block";
			setCurrentVideoPlay(null);
			setCurrentVideo(null);
		}
	}

	const select = (event, value) => {
		event.stopPropagation();

		setFlavour(flavourPath[value]);

		for (let i = 0; i < flavourPath.length; i++) {
			let elem = document.getElementById('choose_' + i);

			if (elem) {
				if (i !== value) {
					elem.style.filter = "brightness(50%) grayscale(70%)";
					elem.style.pointerEvents = "none";
				} else {
					elem.style.filter = "";
					elem.style.pointerEvents = "all"
				}
			}
		}

		for (let i = 0; i < flavourPath.length; i++) {
			let elemChooseButton = document.getElementById('chooseButton_' + i);
			let elemChooseImg = document.getElementById('chooseImg_' + i);
			let elemChooseContent = document.getElementById('chooseContent_' + i);

			if (elemChooseButton && elemChooseImg && elemChooseContent) {
				if (i === value) {
					elemChooseButton.style.backgroundColor = "white";
					elemChooseImg.style.filter = "none";
					elemChooseContent.style.color = "black";
				} else {
					elemChooseButton.style.backgroundColor = "";
					elemChooseImg.style.filter = "invert(100%)";
					elemChooseContent.style.color = "";
				}
			}
		}

		setTimeout(() => {
			props.setTransition(1);

			setTimeout(() => {
				navigate('/flavour2', { state: { bottles: bottles, flavour: flavourPath[value] } });
			}, 500);
		}, 1000);
	}

	useEffect(() => {
		setExplanations(10);

		props.setTransition(0);
	}, [])

	let resize = () => {
		for (let value of flavourPath) {
			let videoContainerElem = document.getElementById(value.id + '_videoContainer');
			let videoElem = document.getElementById(value.id + '_video');

			if (videoContainerElem && videoElem) {
				let width = videoContainerElem.offsetWidth;
				let height = videoContainerElem.offsetHeight;

				if (width / height >= 912/1824) {
					videoElem.style.width = "100%";
					videoElem.style.height = "";

					videoElem.style.left = "0%";
					videoElem.style.top = "50%";
					videoElem.style.transform = ("translateY(-50%)");
				} else {
					videoElem.style.width = "";
					videoElem.style.height = "100%";

					videoElem.style.left = "50%";
					videoElem.style.top = "0%";
					videoElem.style.transform = ("translateX(-50%)");
				}
			}
		}
	}

	useEffect(() => {
		resize();
	});

	useEffect(() => {
		window.addEventListener("resize", () => {
			resize();
		});
		return () => {
			window.addEventListener("resize", () => {
				resize();
			});
		}
	}, []);


	let dataExplanations = {
		time: 10,
		desc: [
			"explanationsFlavour-desc0-0",
			"explanationsFlavour-desc0-1",
			"explanationsFlavour-desc0-2",
			"explanationsFlavour-desc0-3",
		],
		obj: [
			(<div className="explanations-play">
				<div className="explanations-playImg"></div>
				<div className="explanations-playContent">
					<GetTrad id="explanations-play" lang={language} upperCase />
				</div>
			</div>),
			(<div className="explanations-and">{'&'}</div>),
			(<div className="explanations-choose">
				<div className="explanations-chooseImg"></div>
				<div className="explanations-chooseContent">
					<GetTrad id="explanations-choose" lang={language} upperCase />
				</div>
			</div>)
		]
	};

	return (
		<div className="step1">
			<Nav setTransition={props.setTransition} />
			<Step step={0} />
			{/* {(explanations > 0) && <Explanations step={1} setExplanations={setExplanations} data={dataExplanations} />} */}

			{currentVideo === null && <div className="step1-selectFlavour">
				<GetTrad id="step1-selectFlavour1" lang={language} upperCase />
			</div>}

			{flavourPath.map((elem, i) => (
				<div id={'choose_' + i} key={elem.id} className={elem.css} onClick={() => play(elem.id)}>
					<div className="step1-flavourVideoContainer">
						<video id={elem.id + '_video'} className="step1-flavourVideo" src={"/assets/videos/flavour" + (i % 3 + 1) + ".mp4"} style={{ display: "none" }}> </video>
					</div>
					<div id={elem.id + '_videoPlay'} className="step1-flavourVideoPlay"> </div>
					<div className="step1-flavour">
						<GetTrad id={elem.desc} lang={language} upperCase />
						<br /><br />
						<div id={'chooseButton_' + i} className="step1-choose" onClick={(e) => select(e, i)}>
							<div id={'chooseImg_' + i} className="step1-chooseImg"></div>
							<div id={'chooseContent_' + i} className="step1-chooseContent">
								<GetTrad id="step1-choose" lang={language} upperCase />
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default Step1;