// React
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import { Header, Nav, Step } from '../partials/index';
// Utils
import GetTrad from '../../utils/GetTrad';
// CSS
import './home.css';

function Home(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const select = (event, value) => {
		event.stopPropagation();

		let elem = document.getElementById('home-expRight');
		if (value === 'cognac') {
			elem = document.getElementById('home-expLeft');
		}

		elem.style.filter = "brightness(50%) grayscale(70%)";
		elem.style.pointerEvents = "none";

		setTimeout(() => {
			props.setTransition(1);

			setTimeout(() => {
				navigate('/'+value);
			}, 500);
		}, 1000);
	}

	useEffect(() => {
		props.setTransition(0);
	}, []);

	return (
		<div className="home">
			<Nav setTransition={props.setTransition} />
			<Step step={0} />
			<div className="home-selectExp">
				<GetTrad id="home-selectExp" lang={language} upperCase />
			</div>
			<div id='home-expLeft' className="home-expLeft" onClick={(e) => select(e, 'flavour')}>
				<div className="home-expTitle">
					<span><GetTrad id="home-by" lang={language} /><br />
						<span className="home-expTitleScript"><GetTrad id="home-aromaticProfile" lang={language} /></span>
					</span>
				</div>
			</div>
			<div id='home-expRight' className="home-expRight" onClick={(e) => select(e, 'cognac')}>
				<div className="home-expTitle">
					<span><GetTrad id="home-by" lang={language} /><br />
						<span className="home-expTitleScript"><GetTrad id="home-cognac" lang={language} /></span>
					</span>
				</div>
			</div>
		</div>
	);
}

export default Home;