// React
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
// Components
import { Nav, Step } from '../partials/index';
import Explanations from '../Explanations/Explanations';
// Utils
import { perfume } from '../../utils/communication';
import GetTrad from "../../utils/GetTrad";
// Data
import { default as tastes } from "../../data/tastes"
// CSS
import "./cognac.css"

function Results(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const goDiscover = () => {
		props.setTransition(1);

		setTimeout(() => {
			navigate('/discover', { state: { bottle: props.bottle } });
		}, 500);
	}

	useEffect(() => {
		setTimeout(() => {
			goDiscover();
		}, 5000)
	}, []);

	return (
		<div className="c-step3-results">
			<div className="c-step3-resultsContainer">
				<div className="c-step3-resultsContent">
					{props.score >= 2 && <div className="c-step3-resultsOk">
						<GetTrad id="c-step3-resultOk" lang={language} upperCase /><br />
						<span style={{ color: '#63C794', fontSize: '3vw', lineHeight: '5vw' }}><GetTrad id="c-step3-youare100" lang={language} upperCase /></span><br /><br />
						<div className="c-step3-correct">
							<span style={{ color: '#63C794', fontSize: '4vw' }}>100% </span>
							<GetTrad id="c-step3-correct" lang={language} upperCase />
						</div>
					</div>}

					{props.score === 1 && <div className="c-step3-resultsOk">
						<GetTrad id="c-step3-resultOk" lang={language} upperCase /><br />
						<span style={{ color: '#63C794', fontSize: '3vw', lineHeight: '5vw' }}><GetTrad id="c-step3-youare50" lang={language} upperCase /></span><br /><br />
						<div className="c-step3-correct">
							<span style={{ color: '#63C794', fontSize: '4vw' }}>50% </span>
							<GetTrad id="c-step3-correct" lang={language} upperCase />
						</div>
					</div>}

					{props.score <= 0 && <div className="c-step3-resultsOk">
						<span style={{ color: '#63C794' }}><GetTrad id="c-step3-resultKo" lang={language} upperCase /></span>
					</div>}

					<br /><br />
					{props.scents && <div className="c-step3-resultsScent">
						<div className="c-step3-resultsScentLeft">
							<div className="c-step3-resultsScentPreshow" style={{ backgroundImage: `url("/assets/backgrounds/step3-preview/${tastes.get(props.scents[0]).id}.jpg")` }}>
								<div className="c-step3-resultsScentPreshowText">
									<div className="c-step3-resultsScentLeftContent">
										<GetTrad id="c-step3-scent" lang={language} upperCase data={[1]} />
									</div>
									<GetTrad id={tastes.get(props.scents[0]).desc} lang={language} upperCase />
								</div>
							</div>
						</div>
						<div className="c-step3-resultsScentRight">
							<div className="c-step3-resultsScentPreshow" style={{ backgroundImage: `url("/assets/backgrounds/step3-preview/${tastes.get(props.scents[1]).id}.jpg")` }}>
								<div className="c-step3-resultsScentPreshowText">
									<div className="c-step3-resultsScentRightContent">
										<GetTrad id="c-step3-scent" lang={language} upperCase data={[2]} />
									</div>
									<GetTrad id={tastes.get(props.scents[1]).desc} lang={language} upperCase />
								</div>
							</div>
						</div>
					</div>}
				</div>
			</div>
			<div className="c-step3-skip"><div className='arrow'></div><div className="step3-skip-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

function Validation(props) {
	const language = useSelector((state) => state.language.current);

	useEffect(() => {
		props.setTransition(0);
	}, []);

	return (
		<div className="c-step3-validation">
			<div className="c-step3-validationLeft">
				<div className="c-step3-validationLeftContent">
					<GetTrad id="c-step3-scent" lang={language} upperCase data={[1]} />
				</div>
				<div className="c-step3-validationPreshow" style={{ backgroundImage: `url("/assets/backgrounds/step3-validation/${tastes.get(props.selection[0]).img}.jpg")` }}>
					<div className="c-step3-validationPreshowText"><GetTrad id={tastes.get(props.selection[0]).desc} lang={language} upperCase /></div>
				</div>
			</div>
			<div className="c-step3-validationRight">
				<div className="c-step3-validationRightContent">
					<GetTrad id="c-step3-scent" lang={language} upperCase data={[2]} />
				</div>
				<div className="c-step3-validationPreshow" style={{ backgroundImage: `url("/assets/backgrounds/step3-validation/${tastes.get(props.selection[1]).img}.jpg")` }}>
					<div className="c-step3-validationPreshowText"><GetTrad id={tastes.get(props.selection[1]).desc} lang={language} upperCase /></div>
				</div>
			</div>
			<div className="c-step3-validationOpts">
				<div className="c-step3-validationOptsRemove" onClick={() => { props.reset() }}>
					<div className="c-step3-validationOptsRemoveContent">
						<GetTrad id="c-step3-validationOptsRemove" lang={language} upperCase />
					</div>
				</div>
				<div className="c-step3-validationOptsValid" onClick={() => { props.setResult(true) }}>
					<div className="c-step3-validationOptsValidContent">
						<GetTrad id="c-step3-validationOptsValid" lang={language} upperCase />
					</div>
				</div>
			</div>
		</div>
	);
}

function Popup(props) {
	const language = useSelector((state) => state.language.current);

	return (
		<div className="c-step3-popup">
			<div className="c-step3-popupContent">
				<div className="c-step3-popupText">
					<GetTrad id="c-step3-pressTo" lang={language} upperCase />
					<span style={{ color: '#63C794' }}>
						{props.trial === 0 && <GetTrad id={"c-step3-scent"} lang={language} upperCase data={"1"} />}
						{props.trial != 0 && <GetTrad id={"c-step3-scent"} lang={language} upperCase data={"2"} />}
					</span>
				</div>
				<div id='c-step3-popupSmellButton' className="c-step3-popupSmellButton" onClick={() => {
					props.smell();

					setTimeout(() => {
						props.setPopup(false);
					}, 500)
				}}>
					<div className='c-step3-popupSmellButtonInside'>
						<div id='c-step3-smellButtonImg' className="c-step3-smellButtonImg"></div>
						<div id='c-step3-smellButtonContent' className="c-step3-smellButtonContent">
							<GetTrad id={"c-step3-smell"} lang={language} upperCase />
						</div>
					</div>
				</div>
			</div>
			<div className="c-step3-popup-skip" onClick={() => {
				props.setPopup(false);

				props.setDisplayMatch(true);
			}}><div className='arrow'></div><div className="c-step3-skip-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

function Step3(props) {
	const navigate = useNavigate();

	const { bottle } = useLocation().state;

	const language = useSelector((state) => state.language.current);

	const [explanations, setExplanations] = useState(10);
	const [displayMatch, setDisplayMatch] = useState(false);
	const [scents, setScents] = useState(null);
	const [trial, setTrial] = useState(0);
	const [score, setScore] = useState(0);
	const [selection, setSelection] = useState([null, null]);
	const [popup, setPopup] = useState(true);
	const [validation, setValidation] = useState(false);
	const [result, setResult] = useState(false);

	const fire = (elem) => {
		let id = tastes.get(elem).scent;

		let elemButton = document.getElementById('c-step3-popupSmellButton');
		let elemText = document.getElementById('c-step3-smellButtonContent');

		if (elemButton && elemText) {
			elemButton.style.backgroundColor = "white";
			elemText.style.color = "black";
		}

		if (id != 404) { // TODO !
			setTimeout(() => {
				props.setSmell({ time: 2, next: undefined });
				perfume(id);

				elemButton.style.backgroundColor = "";
				elemText.style.color = "";
			}, 200)
		}
	}

	const match = (elem) => {
		let tmp = [selection[0], selection[1]];
		tmp[trial] = elem;
		setSelection(tmp);

		if (elem === scents[trial]) {
			setScore(score + 1);
		}
		setTrial(trial + 1);
		if (trial < 1) {
			setPopup(true);
		}
	}

	const reset = () => {
		setDisplayMatch(false);
		setSelection([null, null]);
		setTrial(0);
		setScore(0);
		setValidation(false);
		setResult(false);
		setPopup(true);
	}

	const smell = () => {
		fire(scents[trial]);

		setTimeout(() => {
			setDisplayMatch(true);
		}, 1000);
	}

	useEffect(() => {
		if (trial === 2) {
			setTimeout(() => {
				props.setTransition(1);

				setTimeout(() => {
					setValidation(true);
				}, 500);
			}, 1000);
		} else if (trial === 1) {
			setDisplayMatch(false);
		}
	}, [trial])

	useEffect(() => {
		let transition = document.getElementById('transition');
		if (transition) {
			transition.style.opacity = 0
		}

		let tmp = [];
		while (tmp.length < 2) {
			tmp.push(bottle.scentStep[Math.floor(Math.random() * bottle.scentStep.length)]);
			if (tmp.length >= 2) {
				if (tmp[0] === tmp[1]) {
					tmp.splice(1, 1);
				}
			}
		}

		console.log("Scents choosen :", tmp);
		setScents(tmp);
	}, []);

	useEffect(() => {
		for (let flavour of bottle.tastes) {
			let taste = tastes.get(flavour);
			let elem = document.getElementById('c-step3-' + taste.css);

			if (elem) {
				if (selection.includes(flavour) && selection[0] != null) {
					elem.style.filter = "brightness(50%) grayscale(70%)";
					elem.style.pointerEvents = "none"
				} else {
					elem.style.filter = "";
					elem.style.pointerEvents = "all";
				}
			}
		}

		for (let flavour of bottle.tastes) {
			let taste = tastes.get(flavour);

			let elemLikeButton = document.getElementById('c-step3-' + taste.css + '_match');
			let elemLikeImg = document.getElementById('c-step3-' + taste.css + '_matchImg');
			let elemLikeContent = document.getElementById('c-step3-' + taste.css + '_matchContent');

			if (elemLikeButton && elemLikeImg && elemLikeContent) {
				if (selection.includes(flavour) && selection.length > 0) {
					elemLikeButton.style.backgroundColor = "white";
					elemLikeContent.style.color = "black";
					elemLikeImg.style.filter = "invert(100%)";
					elemLikeButton.style.pointerEvents = "none"
				} else {
					elemLikeButton.style.backgroundColor = "";
					elemLikeContent.style.color = "";
					elemLikeImg.style.filter = "none";
					elemLikeButton.style.pointerEvents = "all"
				}
			}
		}
	}, [selection])

	let css = ['bottomPartLeft', 'bottomPartCenter', 'bottomPartRight']

	let dataExplanations = {
		time: 10,
		desc: [
			"explanationsCognac-desc2-0",
			"explanationsCognac-desc2-1",
			"explanationsCognac-desc2-2",
			"explanationsCognac-desc2-3"
		],
		obj: [
			(<div className="explanations-smell">
				<div className="explanations-smellImg"></div>
				<div className="explanations-smellContent">
					<GetTrad id="explanations-smell" lang={language} upperCase />
				</div>
			</div>),
			(<div className="explanations-and">{'&'}</div>),
			(<div className="explanations-match">
				<div className="explanations-matchImg"></div>
				<div className="explanations-matchContent">
					<GetTrad id="explanations-match" lang={language} upperCase />
				</div>
			</div>)
		],
		bottle: bottle.name
	};

	return (
		<div className="c-step3">
			<Nav setTransition={props.setTransition} />
			<Step step={2} />
			{/* {explanations > 0 && <Explanations step={3} setExplanations={setExplanations} data={dataExplanations} />} */}

			{popup === true && <Popup trial={trial} smell={smell} setPopup={setPopup} setDisplayMatch={setDisplayMatch} />}
			{result === true && <Results bottle={bottle} scents={scents} score={score} setTransition={props.setTransition} />}
			{validation && <Validation setResult={setResult} reset={reset} selection={selection} setTransition={props.setTransition} />}

			<div className="c-step3-topPart">
				{displayMatch && (
					<React.Fragment>
						<div className="c-step3-topPartContent">
							<GetTrad id={"c-step3-whichScent"} lang={language} upperCase />
						</div>
						<div id='c-step3-smellButton' className="c-step3-smellButton" onClick={() => smell()}>
							<div className='c-step3-smellButtonInside'>
								<div id='c-step3-smellButtonImg' className="c-step3-smellButtonImg"></div>
								<div id='c-step3-smellButtonContent' className="c-step3-smellButtonContent">
									<GetTrad id={"c-step3-smellAgain"} lang={language} upperCase />
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
			<div className="c-step3-bottomPart">
				{bottle.tastes.map((elem, i) => (
					<div key={elem} className={'c-step3-' + css[i]}>
						<div id={'c-step3-' + tastes.get(elem).css} className='c-step3-scent' style={{ backgroundImage: `url("/assets/backgrounds/step3-selection/${tastes.get(elem).img}.jpg")` }}></div>
						<div className="c-step3-flavour">
							<div className='c-step3-flavourContent' >
								<GetTrad id={tastes.get(elem).desc} lang={language} upperCase />
								<div className="c-step3-matchButton" style={{ display: displayMatch || selection.includes(elem) ? 'block' : 'none' }}>
									<br /><br />
									<div id={'c-step3-' + tastes.get(elem).css + '_match'} className="c-step3-match" onClick={() => match(elem)}>
										<div id={'c-step3-' + tastes.get(elem).css + '_matchImg'} className="c-step3-matchImg"></div>
										<div id={'c-step3-' + tastes.get(elem).css + '_matchContent'} className="c-step3-matchContent">
											<GetTrad id="c-step3-match" lang={language} upperCase />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="c-step3-skip" onClick={() => {
				props.setTransition(1);

				setTimeout(() => {
					navigate('/discover', { state: { bottle: bottle } });
				}, 500);
			}}><div className='arrow'></div><div className="c-step3-skip-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

export default Step3;