import {
    LANGUAGE_CURRENT
} from './types';

export function setLanguageCurrent(lang) {
    return (dispatch, getState) => {      
		dispatch({
            type: LANGUAGE_CURRENT,
            id: lang
        });
	};
};