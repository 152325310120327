// React
import { useEffect, useState, useImperativeHandle } from "react";
// CSS
import "./loadingBar.css"

function LoadingBar(props) {
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsActive(props.toggle);
        }, 2000)
    }, [props.toggle])

    useEffect(() => {
        let interval = null;
        if (seconds > props.time) {
            if (props.onOver)
                props.onOver();
        }
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 0.01)
            }, 10);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds])

    return (
        <div className="loadingBar-container">
            <div className="loadingBar-content" style={{ width: (seconds * 100 / props.time) + '%' }}>
            </div>
        </div>
    );
}

export default LoadingBar;