// React
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
// Components
import { Nav, Step } from '../partials/index';
import Explanations from '../Explanations/Explanations';
// Utils
import GetTrad from '../../utils/GetTrad';
import { perfume } from '../../utils/communication';
// Data
import { default as tastes } from '../../data/tastes';
// CSS
import './flavour.css';

function Validation(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const goNext = () => {
		setTimeout(() => {
			props.setTransition(1);

			setTimeout(() => {
				navigate('/flavour3', { state: { bottles: props.bottles, flavour: props.flavour, selection: props.selection } });
			}, 500);
		}, 1000);
	}

	useEffect(() => {
		props.setTransition(0);
	}, []);

	return (
		<div className="step2-validation">
			<div className="step2-validation">
				<div className="step2-validation-desc-line">
					<GetTrad id='step2-validation-desc-line' lang={language} upperCase />
				</div>
				<div className="step2-validationLeft">
					<div className="step2-validationPreshow" style={{ backgroundImage: `url(/assets/backgrounds/step2-validation/${tastes.get(props.selection[0]).img}.jpg)` }}>
						<div className="step2-validationPreshowText"><GetTrad id={tastes.get(props.selection[0]).desc} lang={language} upperCase /></div>
					</div>
				</div>
				<div className="step2-validationCenter">
					<div className="step2-validationPreshow" style={{ backgroundImage: `url(/assets/backgrounds/step2-validation/${tastes.get(props.selection[1]).img}.jpg)` }}>
						<div className="step2-validationPreshowText"><GetTrad id={tastes.get(props.selection[1]).desc} lang={language} upperCase /></div>
					</div>
				</div>
				<div className="step2-validationRight">
					<div className="step2-validationPreshow" style={{ backgroundImage: `url(/assets/backgrounds/step2-validation/${tastes.get(props.selection[2]).img}.jpg)` }}>
						<div className="step2-validationPreshowText"><GetTrad id={tastes.get(props.selection[2]).desc} lang={language} upperCase /></div>
					</div>
				</div>
			</div>
			<div className="step2-validationOpts">
				<div className="step2-validationOptsRemove" onClick={() => { props.reset() }}>
					<div className="step2-validationOptsRemoveContent">
						<GetTrad id="step2-validationOptsRemove" lang={language} upperCase />
					</div>
				</div>
				<div className="step2-validationOptsValid" onClick={() => { goNext() }}>
					<div className="step2-validationOptsValidContent">
						<GetTrad id="step2-validationOptsValid" lang={language} upperCase />
					</div>
				</div>
			</div>
		</div>
	)
}

function Popup(props) {
	const language = useSelector((state) => state.language.current);

	return (
		<div className="step2-popup">
			<div className="step2-popupContent">
				<div className="step2-popupChooseImg"></div>
				<div className="step2-popupDesc">
					<div className="step2-popupDesc-line">
						<GetTrad id='step2-selected' lang={language} upperCase data={[props.selected.length.toString()]} /><br />
						<span style={{ color: '#63C794' }}><GetTrad id='step2-moreSelect' lang={language} upperCase data={[(3 - props.selected.length).toString()]} /></span>
					</div>
				</div>
			</div>
			<div className="step2-popupNext"><div className='arrow'></div><div className="step2-popupNext-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

/* function Step2stay(props) {
	const language = useSelector((state) => state.language.current);

	return (<div className="step2-selectSmell">
		<div className="step2-selectSmell-line">
			{props.selected.length.toString()} <GetTrad id='step2-selected' lang={language} upperCase data={[""]} />
		</div>
		{props.selected.length <= 3 && <div className="" style={{ display: 'inline-block', backgroundImage: 'url(./assets/step' + (props.selected.length).toString() + '.png)', width: (420 * 100 / 2736) + 'vw', aspectRatio: '420/86', backgroundSize: 'cover' }}></div>}
	</div>)
} */

function Step2(props) {
	const language = useSelector((state) => state.language.current);

	const { bottles, flavour } = useLocation().state;

	const [explanations, setExplanations] = useState(10);
	const [selected, setSelected] = useState([]);
	const [timeUntilClick, setTimeUntilClick] = useState(Date.now());
	const [popup, setPopup] = useState(false);
	const [validation, setValidation] = useState(false);

	const selectRef = useRef("");

	const fire = (elem) => {
		let id = tastes.get(elem).scent;

		let elemButton = document.getElementById('step2-smellButton-' + elem);

		if (elemButton) {
			elemButton.style.filter = "invert()";
		}

		if (id != 404) { // TODO !
			setTimeout(() => {
				props.setSmell({ time: 2, next: undefined });
				perfume(id);

				elemButton.style.filter = "";
			}, 200)
		}
	}

	const reset = () => {
		setTimeUntilClick(Date.now());
		setValidation(false);
		setPopup(false);
		select(null);

		for (let tmp of flavour.smells) {
			let taste = tastes.get(tmp);

			let elem = document.getElementById('step2-' + taste.css);

			if (elem) {
				elem.style.filter = "";
				elem.style.pointerEvents = "all"
			}
		}
	}

	const select = (elem) => {
		if (elem === null) {
			selected.splice(0, selected.length);
		} else if (selected.length < 3 && elem) {
			if (selected.includes(elem)) {
				selected.splice(selected.indexOf(elem), 1);
			} else {
				selected.push(elem)
			}
		}
		if (selected.length === 3) {
			for (let tmp of selected) {
				for (let bottle of tastes.get(tmp).bottles) {
					for (let tmp of bottles) {
						if (tmp.id === bottle) {
							tmp.score++;
							break;
						}
					}
				}
			}

			for (let tmp of flavour.smells) {
				let taste = tastes.get(tmp);

				let elem = document.getElementById('step2-' + taste.css);

				if (elem) {
					if (selected.includes(tmp)) {
						elem.style.filter = "";
						elem.style.pointerEvents = "all"
					} else {
						elem.style.filter = "brightness(50%) grayscale(70%)";
						elem.style.pointerEvents = "none";
					}
				}
			}

			setTimeout(() => {
				props.setTransition(1);

				setTimeout(() => {
					setValidation(true);
				}, 500);
			}, 1000);
		} else {
			for (let tmp of flavour.smells) {
				let taste = tastes.get(tmp);

				let elem = document.getElementById('step2-' + taste.css);

				if (elem) {
					elem.style.filter = "";
					elem.style.pointerEvents = "all"
				}
			}
		}

		for (let tmp of flavour.smells) {
			let taste = tastes.get(tmp);

			let elemChooseButton = document.getElementById('step2-' + taste.css + '_chooseButton');
			let elemChooseImg = document.getElementById('step2-' + taste.css + '_chooseImg');
			let elemChooseContent = document.getElementById('step2-' + taste.css + '_chooseContent');

			if (elemChooseButton && elemChooseImg && elemChooseContent) {
				if (selected.includes(tmp) && selected.length > 0) {
					elemChooseButton.style.backgroundColor = "white";
					elemChooseImg.style.filter = "none";
					elemChooseContent.style.color = "black";
				} else {
					elemChooseButton.style.backgroundColor = "";
					elemChooseImg.style.filter = "invert(100%)";
					elemChooseContent.style.color = "";
				}
			}
		}
	}

	useEffect(() => {
		let val = setInterval(() => {
			if (Date.now() - timeUntilClick > 15000) {
				setPopup(true);
			}
		}, 200)

		let updateTime = (e) => {
			setTimeUntilClick(Date.now());
			setPopup(false);
		}

		document.addEventListener("click", updateTime, false);

		return () => {
			clearInterval(val);
			document.removeEventListener("click", updateTime, false);
		}
	}, [timeUntilClick]);

	useEffect(() => {
		props.setTransition(0);
	}, []);

	let dataExplanations = {
		time: 10,
		desc: [
			"explanationsFlavour-desc1-0",
			"explanationsFlavour-desc1-1",
			"explanationsFlavour-desc1-2",
			"explanationsFlavour-desc1-3",
		],
		obj: [
			(<div className="explanations-smell">
				<div className="explanations-smellImg"></div>
				<div className="explanations-smellContent">
					<GetTrad id="explanations-smell" lang={language} upperCase />
				</div>
			</div>),
			(<div className="explanations-and">{'&'}</div>),
			(<div className="explanations-choose">
				<div className="explanations-chooseImg"></div>
				<div className="explanations-chooseContent">
					<GetTrad id="explanations-choose" lang={language} upperCase />
				</div>
			</div>)
		]
	};

	selectRef.current = selected.length;

	return (
		<div className="step2">
			<Nav setTransition={props.setTransition} />
			<Step step={1} />
			{/* {explanations > 0 && <Explanations step={2} setExplanations={setExplanations} data={dataExplanations} />} */}

			{popup && <Popup selected={selected} />}

			{validation && <Validation reset={reset} selection={selected} flavour={flavour} bottles={bottles} setTransition={props.setTransition} />}

			<div className="step2-selectSmell">
				<GetTrad id="step2-selectSmell" lang={language} upperCase />
			</div>

			{/* <Step2stay selected={selected} selectedRef={selectRef} /> */}

			{flavour.smells.map((elem, i) => (
				<div id={'step2-' + tastes.get(elem).css} key={elem} className={'step2-smell' + (i + 1)} style={{ backgroundImage: `url(/assets/backgrounds/step2-selection/${tastes.get(elem).img}.jpg)` }}>
					<div id={'step2-smellButton-' + elem} className="step2-smellIcon" onClick={() => fire(elem)} unselectable="on"></div>
					<div className="step2-smell">
						<div className="step2-smellText">
							<div className="step2-smellText-content">
								<GetTrad id={tastes.get(elem).desc} lang={language} upperCase />
							</div>
						</div>
						{/* <div id={'step2-smellButton-' + elem} className="step2-smellButton" onClick={() => fire(elem)}>
							<div className={'step2-smellButtonContainer'}>
								<div id={'step2-smellButtonImg-' + elem} className="step2-smellButtonImg"></div>
								<div id={'step2-smellButtonContent-' + elem} className="step2-smellButtonContent">
									<GetTrad id="step2-smellButton" lang={language} upperCase />
								</div>
							</div>
						</div> */}
						<div id={'step2-' + tastes.get(elem).css + '_chooseButton'} className="step2-choose" onClick={() => select(elem)}>
							<div className={'step2-chooseContainer'}>
								<div id={'step2-' + tastes.get(elem).css + '_chooseImg'} className="step2-chooseImg"></div>
								<div id={'step2-' + tastes.get(elem).css + '_chooseContent'} className="step2-chooseContent">
									<GetTrad id="step2-choose" lang={language} upperCase />
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default Step2;