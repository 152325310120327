// React
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import GetTrad from "../../utils/GetTrad";
import LoadingBar from "../partials/LoadingBar";
// CSS
import "./smell.css"

function Smell(props) {
	const language = useSelector((state) => state.language.current);

	useEffect(() => {
		let elem = document.getElementById('smellVideo');

		elem.currentTime = 0;
		elem.style.display = "block";
		elem.style.objectFit = "fill";
		elem.play();

		let to = setTimeout(() => {
			if (props.smell && props.smell.next) {
				props.smell.next()
			}
			props.setSmell({ time: 0, next: props.smell.next });
		}, ((props.smell.time + 2) * 1000) + 500 || 3000)

		elem.onclick = () => {
			clearTimeout(to);
			if (props.smell && props.smell.next) {
				props.smell.next()
			}
			props.setSmell({ time: 0, next: props.smell.next });
		}

		elem.onended = () => {
			
		}
	}, [])

	let resize = () => {
		let videoContainerElem = document.getElementById('smellVideoContainer');
		let videoElem = document.getElementById('smellVideo');

		if (videoContainerElem && videoElem) {
			let width = videoContainerElem.offsetWidth;
			let height = videoContainerElem.offsetHeight;

			if (width / height >= 2736/1824) {
				videoElem.style.width = "100%";
				videoElem.style.height = "";

				videoElem.style.left = "0%";
				videoElem.style.top = "50%";
				videoElem.style.transform = ("translateY(-50%)");
			} else {
				videoElem.style.width = "";
				videoElem.style.height = "100%";

				videoElem.style.left = "50%";
				videoElem.style.top = "0%";
				videoElem.style.transform = ("translateX(-50%)");
			}
		}
	}

	useEffect(() => {
		resize();
	});

	useEffect(() => {
		window.addEventListener("resize", () => {
			resize();
		});
		return () => {
			window.addEventListener("resize", () => {
				resize();
			});
		}
	}, []);

	return (
		<div className="smell">
			<div id='smellVideoContainer' className="smellVideoContainer"><video muted loop id={'smellVideo'} className="smellVideo" src={'/assets/videos/Smell.mp4'}> </video></div>
			<LoadingBar id="loadingBar" toggle={true} time={props.smell.time} />
			<div className="smell-center-content">
				<div className="smell-content"><GetTrad id='smell-getCloser' lang={language} upperCase /></div>
			</div>
			<div className="c-step3-skip"><div className='arrow'></div><div className="step3-skip-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

export default Smell;