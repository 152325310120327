/* eslint-disable import/no-anonymous-default-export */
import {
	LANGUAGE_CURRENT
} from '../actions/types';

const initialState = {
	current: "en"
};

export default function (state = initialState, action) {
	switch (action.type) {
		case LANGUAGE_CURRENT:
			return {
				...state,
				current: action.id
			};
		default:
			return state;
	};
};
