export default [
    {
        id: "fruityAndJuicy",
        desc: "step1-fruityAndJuicy",
        css: "step1-flavourLeft",
        bottles: [
            "vsopPrivilege",
            "jamesHennessy"
        ],
        smells: [
            "tasteOrangeBlossom",
            "tasteFruityApricot",
            "tasteWhiteHoney",
            "tasteVanillaPod"
        ]
    },
    {
        id: "richAndRounded",
        desc: "step1-richAndRounded",
        css: "step1-flavourCenter",
        bottles: [
            "hennessyXXO",
            "paradis"
        ],
        smells: [
            "tasteBlondTobacco",
            "tasteMango",
            "tasteSaltedButterCaramel",
            "tasteSandalwood"
        ]
    },
    {
        id: "deepAndIntense",
        desc: "step1-deepAndIntense",
        css: "step1-flavourRight",
        bottles: [
            "hennessyXO",
            "hennessyXXO"
        ],
        smells: [
            "tasteOakwood",
            "tasteChocolate",
            "tasteSandalwood",
            "tasteSaltedButterCaramel"
        ]
    }
];