const axios = require('axios');

let URL = process.env.REACT_APP_API_URL;

function perfume(p) {
    console.log("Fire " + p);

    let row = Math.floor(p / 5);
    let col = Math.floor(p % 5);

    let elemSimulator = document.getElementById('row-'+row+'_col-'+col);

    if (elemSimulator) {
        elemSimulator.style.backgroundColor = 'red'

        setTimeout(() => {
            elemSimulator.style.backgroundColor = 'chartreuse'
        }, 2000)
    }

    axios.get(URL + 'perfume/fire/' + p)
        .then(function (response) {
            // handle success
            console.log(response);
        })
};

function initSession() {
    console.log("Init session");

    axios.get(URL + 'session/init')
        .then(function (response) {
            // handle success
            console.log(response);
        })
};

function endSession() {
    console.log("End session");

    axios.get(URL + 'session/end')
        .then(function (response) {
            // handle success
            console.log(response);
        })
};

export { perfume, initSession, endSession };