import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import "./simulator.css"

function Simulator() {

    const columns = (row) => {
        const items = [];

        for (let i = 0; i < 5; i++) {
            items.push(<div className="simulator-spray" key={'row-'+row+'_col-'+i} id={'row-'+row+'_col-'+i}> </div>);
        }

        return items;
    }

    const lines = () => {
        const items = [];

        for (let i = 0; i < 3; i++) {
            items.push(<div className="simulator-row" key={'row'+i} id={'row'+i}>{columns(i)}</div>);
        }

        return items;
    }


	return (
		<div className="simulator">
            <div className="simulator-container">
                {lines()}
            </div>
		</div>
	);
}

export default Simulator;