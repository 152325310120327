// React
import { useNavigate } from 'react-router-dom';
// CSS
import "./nav.css"

function Nav(props) {
    const navigate = useNavigate();

    const goQuit = () => {
        props.setTransition(1);
        var x = setTimeout('');
        for (var i = 0; i < x; i++)
            clearTimeout(x);

        navigate('/home');
    }

    const goBack = () => {        
        var x = setTimeout('');
        for (var i = 0; i < x; i++)
            clearTimeout(x);

        navigate(-1);
    }

    return (
        <div className="nav">
            <div className='nav-home' onClick={() => goQuit()}></div>
            <div className='nav-back' onClick={() => goBack()}></div>
            <div className='nav-void'></div>
        </div>
    );
}

export default Nav;