// redux
import { combineReducers } from 'redux';
// reducers
import reducerLanguage from './reducer_language';

const appReducer = combineReducers({
	language: reducerLanguage,
});

const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export default rootReducer;