export default [
    {
        id: 'vsopPrivilege',
        scent: 1,
        name: 'Hennessy V.S.O.P',
        adj: 'discover-adjClassic',
        tastes: [
            "tasteWhiteHoney",
            "tasteOrangeBlossom",
            "tasteOakwood",
        ],
        scentStep: [
            "tasteWhiteHoney",
            "tasteOrangeBlossom"
        ],
        sameFlavourProfile: 'jamesHennessy',
        discovery: 'hennessyXO',
        score: 0,
        bottleImg: "url('/assets/backgrounds/bottles/bottle-vsopPrivilege.png')",
        bottleVideo: "/assets/videos/bottles/bottle-vsopPrivilege.mp4",
        bgImg: "url('/assets/backgrounds/discovers/discover-vsopPrivilege.jpg')",
        cognacsImg: "url('/assets/backgrounds/cognacs/cognacs-vsopPrivilege.jpg')",
        css: "c-step1-bottle0",
        flavourProfile: 'desc-fruityAndJuicy',
        pictos: [
            "url('/assets/pictos/coktail.png')",
            "url('/assets/pictos/neat.png')",
            "url('/assets/pictos/onTheRocks.png')"
        ]
    },
    {
        id: 'jamesHennessy',
        scent: 2,
        name: 'James Hennessy',
        adj: 'discover-adjContemporary',
        tastes: [
            "tasteVanillaPod",
            "tasteFruityApricot",
            "tasteTonkaBean",
        ],
        scentStep: [
            "tasteFruityApricot",
            "tasteVanillaPod"
        ],
        sameFlavourProfile: 'vsopPrivilege',
        discovery: 'hennessyXO',
        score: 0,
        bottleImg: "url('/assets/backgrounds/bottles/bottle-jamesHennessy.png')",
        bottleVideo: '/assets/videos/bottles/bottle-jamesHennessy.mp4',
        bgImg: "url('/assets/backgrounds/discovers/discover-jamesHennessy.jpg')",
        cognacsImg: "url('/assets/backgrounds/cognacs/cognacs-jamesHennessy.jpg')",
        css: "c-step1-bottle1",
        flavourProfile: 'desc-fruityAndJuicy',
        pictos: [
            "url('/assets/pictos/neat.png')"
        ]
    },
    {
        id: 'hennessyXO',
        scent: 3,
        name: 'Hennessy X.O',
        adj: 'discover-adjIconic',
        tastes: [
            "tasteChocolate",
            "tasteOakwood",
            "tasteBlackPepper"
        ],
        scentStep: [
            "tasteChocolate",
            "tasteOakwood"
        ],
        sameFlavourProfile: 'hennessyXXO',
        discovery: 'paradis',
        score: 0,
        bottleImg: "url('/assets/backgrounds/bottles/bottle-hennessyXO.png')",
        bottleVideo: '/assets/videos/bottles/bottle-hennessyXO.mp4',
        bgImg: "url('/assets/backgrounds/discovers/discover-hennessyXO.jpg')",
        cognacsImg: "url('/assets/backgrounds/cognacs/cognacs-hennessyXO.jpg')",
        css: "c-step1-bottle2",
        flavourProfile: 'desc-deepAndIntense',
        pictos: [
            "url('/assets/pictos/neat.png')",
            "url('/assets/pictos/withADashOfWater.png')",
            "url('/assets/pictos/onTheRocks.png')",
            "url('/assets/pictos/overIce.png')",
            "url('/assets/pictos/coktail.png')"
        ]
    },
    {
        id: 'hennessyXXO',
        scent: 4,
        name: 'Hennessy X.X.O',
        adj: 'discover-adjEpicurean',
        tastes: [
            "tasteSaltedButterCaramel",
            "tasteSandalwood",
            "tasteCandiedOrange"
        ],
        scentStep: [
            "tasteSaltedButterCaramel",
            "tasteSandalwood"
        ],
        sameFlavourProfile: 'hennessyXO',
        discovery: 'paradis',
        score: 0,
        bottleImg: "url('/assets/backgrounds/bottles/bottle-hennessyXXO.png')",
        bottleVideo: '/assets/videos/bottles/bottle-hennessyXXO.mp4',
        bgImg: "url('/assets/backgrounds/discovers/discover-hennessyXXO.jpg')",
        cognacsImg: "url('/assets/backgrounds/cognacs/cognacs-hennessyXXO.jpg')",
        css: "c-step1-bottle3",
        flavourProfile: 'desc-deepAndIntense',
        pictos: [
            "url('/assets/pictos/neat.png')"
        ]
    },
    {
        id: 'paradis',
        scent: 5,
        name: 'Hennessy Paradis',
        adj: 'discover-adjHarmonious',
        tastes: [
            "tasteBlondTobacco",
            "tasteSaltedButterCaramel",
            "tasteMango"
        ],
        scentStep: [
            "tasteBlondTobacco",
            "tasteMango"
        ],
        sameFlavourProfile: 'hennessyXXO',
        discovery: 'hennessyXO',
        score: 0,
        bottleImg: "url('/assets/backgrounds/bottles/bottle-paradis.png')",
        bottleVideo: '/assets/videos/bottles/bottle-paradis.mp4',
        bgImg: "url('/assets/backgrounds/discovers/discover-paradis.jpg')",
        cognacsImg: "url('/assets/backgrounds/cognacs/cognacs-paradis.jpg')",
        css: "c-step1-bottle4",
        flavourProfile: 'desc-richAndRounded',
        pictos: [
            "url('/assets/pictos/coktailParadis.png')"
        ]
    },
]