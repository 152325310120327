import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import GetTrad from "../../utils/GetTrad";
import "./header.css"

function Header(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	useEffect(() => {
		let elem = document.getElementById('quit');

		elem.onclick = (e) => {
			e.stopPropagation();

			props.setStandBy(true);

			setTimeout(() => {
				navigate('/');
			}, 200)
		}
	})

	useEffect(() => {

	}, [props])

	return (
		<div className="header">
			{props.logo && <div className="header-logo"></div>}
			<div id='quit' className="header-quit" onClick={(e) => {
				e.stopPropagation();

				props.setStandBy(true);

				setTimeout(() => {
					navigate('/');
				}, 200)
			}}>
				<GetTrad id="header-quit" lang={language} upperCase />
			</div>
		</div>
	);
}

export default Header;