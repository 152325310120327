// React
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, Switch } from "react-router-dom";
// Components
import { default as Language } from './components/Language/Language';
import { default as Home } from './components/Home/Home';
import { Step1 as FStep1, Step2 as FStep2, Step3 as FStep3 } from './components/Flavour/index';
import { Step1 as CStep1, Step2 as CStep2, Step3 as CStep3 } from './components/Cognac/index';
import { default as Discover } from './components/Discover/Discover';
import { default as ForFurther } from './components/ForFurther/ForFurther';
import { default as Thanks } from './components/ForFurther/Thanks';
import { default as Simulator } from './components/Simulator/Simulator';
import { default as Smell } from './components/Smell/Smell';
import { Header } from "./components/partials";

import { initSession, endSession} from "./utils/communication"
// CSS
import './style/css/App.css';

function App() {
    const navigate = useNavigate();

    const [timeUntilClick, setTimeUntilClick] = useState(Date.now());
    const [standBy, setStandBy] = useState(false);
    const [pointer, setPointer] = useState(false);
    const [logo, setLogo] = useState(true);

    const [transition, setTransition] = useState(1);
    const [simul, setSimul] = useState(false);
    const [smell, setSmell] = useState({time: 0, next: undefined});

    useEffect(() => {
        if (standBy === true) {
            setTimeout(() => {
                setPointer(true);
            }, 1000);
        } else {
            setPointer(false);
            setTimeout(() => {
                setPointer(false);
            }, 1000);
        }
    }, [standBy])

    /*useEffect(() => {
        setInterval(function(){

            window.air.sendParfum();

            if(window && window.hasOwnProperty("air")){
                console.log("test")
                window.air.sendParfum();
            }else{
                console.log("no")
            }
            
        }, 2000)
    }, [])*/

    useEffect(() => {
        setTransition(0);
    }, [transition])

    useEffect(() => {
        let val = setInterval(() => {
            if (Date.now() - timeUntilClick > 60000) {
                let x = setTimeout('');
                for (var i = 0; i < x; i++) {
                    clearTimeout(x);
                }
                setStandBy(true);

                setTimeout(() => {
                    navigate('/');
                }, 1000);
            }
        }, 200)

        let updateTime = (e) => {
            setTimeUntilClick(Date.now());
            setStandBy(false);
        }

        document.addEventListener("click", updateTime, false);

        return () => {
            clearInterval(val);
            document.removeEventListener("click", updateTime, false);
        }
    }, [timeUntilClick]);

    useEffect(() => {
        let video = document.getElementById('standByVideo');
        if (video) {
            if (standBy === true) {
                video.currentTime = 0;
                video.style.display = "block";
                video.style.objectFit = "fill";
                video.play();
                endSession();
            } else {
                video.pause();
                video.currentTime = 0;
            }
        }
    }, [standBy])

    useEffect(() => {
        let simulator = (e) => {
            if (e.code === 'KeyS') {
                setSimul(!simul);
            }
        }

        document.addEventListener("keydown", simulator, false);

        return () => document.removeEventListener("keydown", simulator, false);
    }, [simul]);


    let resize = () => {
        let videoContainerElem = document.getElementById('standByVideoContainer');
        let videoElem = document.getElementById('standByVideo');

        if (videoContainerElem && videoElem) {
            let width = videoContainerElem.offsetWidth;
            let height = videoContainerElem.offsetHeight;

            if (width / height >= 2736 / 1824) {
                videoElem.style.width = "100%";
                videoElem.style.height = "";

                videoElem.style.left = "0%";
                videoElem.style.top = "50%";
                videoElem.style.transform = ("translateY(-50%)");
            } else {
                videoElem.style.width = "";
                videoElem.style.height = "100%";

                videoElem.style.left = "50%";
                videoElem.style.top = "0%";
                videoElem.style.transform = ("translateX(-50%)");
            }
        }
    }

    useEffect(() => {
        resize();
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
            resize();
        });
        return () => {
            window.addEventListener("resize", () => {
                resize();
            });
        }
    }, []);

    return (
        <React.Fragment>
            {standBy && (
                <div id={'standByVideoContainer'} className="standByVideoContainer">
                    <video loop muted id={'standByVideo'} className="standByVideo" src={"./assets/videos/standByVideo.mp4"}> </video>
                    {pointer && <div className={'videoPointer'}> </div>}
                </div>
            )}

            <div id="transition" style={{ opacity: transition }}></div>
            {smell.time > 0 && <Smell smell={smell} setSmell={setSmell} />}
            <Header logo={logo} setStandBy={setStandBy} />
            <Routes>
                <Route path="/" exact element={<Language setTransition={setTransition} />} />
                <Route path="/language" exact element={<Language setTransition={setTransition} />} />
                <Route path="home" exact element={<Home setTransition={setTransition} />} />

                <Route path="flavour" exact element={<Navigate replace to="/flavour1" />} />
                <Route path="flavour1" exact element={<FStep1 setSmell={setSmell} setTransition={setTransition} />} />
                <Route path="flavour2" exact element={<FStep2 setSmell={setSmell} setTransition={setTransition} />} />
                <Route path="flavour3" exact element={<FStep3 setSmell={setSmell} setTransition={setTransition} />} />

                <Route path="cognac" exact element={<Navigate replace to="/cognac1" />} />
                <Route path="cognac1" exact element={<CStep1 setSmell={setSmell} setTransition={setTransition} />} />
                <Route path="cognac2" exact element={<CStep2 setSmell={setSmell} setTransition={setTransition} />} />
                <Route path="cognac3" exact element={<CStep3 setSmell={setSmell} setTransition={setTransition} />} />

                <Route path="discover" exact element={<Discover setSmell={setSmell} setTransition={setTransition} />} />
                <Route path="forfurther" exact element={<ForFurther setTransition={setTransition} setLogo={setLogo} />} />
                <Route path="thanks" exact element={<Thanks setTransition={setTransition} setLogo={setLogo} setStandBy={setStandBy} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            {simul && <Simulator />}
        </React.Fragment>
    );
}

export default App;
