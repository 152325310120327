// React
import { useEffect } from "react";
import { useSelector } from 'react-redux'
// Utils
import { default as GetTrad, getTrad } from "../../utils/GetTrad";
// CSS
import "./explanations.css"

function ExplanationsSpec(props) {
	const language = useSelector((state) => state.language.current);

	useEffect(() => {
		/*setTimeout(() => {
			props.setExplanations(0);
		}, props.data ? props.data.time * 1000 : 5000)*/
	}, []);
	
	return (
		<div className="explanations">
			<div className="explanations-content">
				<div className="explanationsSpec-desc">
					{props.data.desc.map((elem, index) => {
						let data = null;
						let uppercase = true;

						if (props.data.bottle) {
							data = [];
							data.push(props.data.bottle)
						}

						return (<div key={elem + '_' + index} className="explanations-desc-line">
							<GetTrad id={elem} lang={language} upperCase={uppercase} data={data} /><br />
						</div>)
					})}
				</div><br /><br />
				<div className="step3-explanations-elemLeft" style={{ backgroundImage: `url(/assets/backgrounds/step3-preview/${props.data.flavour.id}.jpg)` }}>
					<div className='step3-explanations-content'><GetTrad id={'desc-' + props.data.flavour.id} lang={language} upperCase /></div>
				</div>
				{props.data.selection.map((elem, id) => {
					return (
						<div key={elem + '_' + id} className="step3-explanations-elemRight" style={{ backgroundImage: `url(/assets/backgrounds/step3-preview/${elem}.jpg)` }}>
							<div className='step3-explanations-content'><GetTrad id={'desc-' + elem} lang={language} upperCase /></div>
						</div>
					)
				})}<br />
				<div className="explanationsSpec-desc">
					<div className="explanations-validationOptsValid" onClick={() => { props.setExplanations(0) }}>
						<div className="explanations-validationOptsValidContent">
							<GetTrad id="explanationsSpec-discover" lang={language} upperCase data={[props.bottleName]} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExplanationsSpec;