import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Nav from "../partials/Nav"
import GetTrad from "../../utils/GetTrad";
import "./forfurther.css"
import Slider from 'react-rangeslider'

function Thanks(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const goQuit = () => {
		props.setTransition(1);

		props.setStandBy(true);

		setTimeout(() => {
			navigate('/');
		}, 500);
	}

	useEffect(() => {
		setTimeout(() => {
			goQuit();
		}, 5000)
	}, []);

	useEffect(() => {
		props.setLogo(false);
		props.setTransition(0);

		return () => props.setLogo(true);
	}, [])

	return (
		<div className="thanks">
			<Nav setTransition={props.setTransition} />

			<div className="forfurther-logo"></div>

			<div className="thanks-text">
					<GetTrad id='forfurther-thanks1' lang={language} upperCase /><br />
					<GetTrad id='forfurther-thanks2' lang={language} upperCase />
					<div className="thanks-valid"> </div>
			</div>
		</div>
	);
}

export default Thanks;