import { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Nav from "../partials/Nav"
import GetTrad from "../../utils/GetTrad";
import "./forfurther.css"
import Slider from 'react-rangeslider'

function ForFurther(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const [timeUntilClick, setTimeUntilClick] = useState(Date.now());
	const [goOut, setGoOut] = useState(false);
	const [horizontal, setHorizontal] = useState(6);

	useEffect(() => {
		if (goOut === true) {
			setTimeUntilClick(Date.now());
			let val = setInterval(() => {
				if (Date.now() - timeUntilClick > 1000) {
					let x = setTimeout('');
					for (var i = 0; i < x; i++) {
						clearTimeout(x);
					}

					setTimeout(() => {
						navigate('/thanks');
					}, 200)
				}
			}, 200)

			let updateTime = (e) => {
				setTimeUntilClick(Date.now());
				props.setStandBy(false);
			}

			document.addEventListener("click", updateTime, false);

			return () => {
				clearInterval(val);
				document.removeEventListener("click", updateTime, false);
			}
		}
	}, [timeUntilClick, goOut]);

	useEffect(() => {
		props.setLogo(false);

		let elems = document.getElementsByClassName('rangeslider__handle-label')
		for (let elem of elems) {
			elem.classList.add('rangeslider__handle-label-off');
		}

		let transition = document.getElementById('transition');

		if (transition) {
			transition.style.opacity = 0
		}

		return () => props.setLogo(true);
	}, [])

	const handleChangeHorizontal = (value) => {
		setTimeUntilClick(Date.now());
		let elems = document.getElementsByClassName('rangeslider__handle-label-off')
		for (let elem of elems) {
			elem.classList.remove('rangeslider__handle-label-off');
		}
		setHorizontal(value);
		setGoOut(true);
	}

	const horizontalLabels = {
		1: '1',
		10: '10'
	}

	return (
		<div className="forfurther">
			<Nav setTransition={props.setTransition} />

			<div className="forfurther-logo"></div>

			{/* <div className="forfurther-thanks">
				<GetTrad id="forfurther-thanks" lang={language} upperCase />
			</div> */}

			<div className="forfurther-reachOut">
				<div className="forfurther-reachOutPicto"> </div>
				<div className="forfurther-reachOutText">
					<span className="forfurther-reachOutRequest"><GetTrad id="forfurther-reachOutRequest" lang={language} upperCase /></span><br />
					<span className="forfurther-reachOutDiscovery"><GetTrad id="forfurther-reachOut1" lang={language} /></span>
				</div>
			</div>

			<div className="forfurther-recommand">
				<div className="forfurther-recommandText">
					<GetTrad id='forfurther-recommandText' lang={language} upperCase /><br />
				</div>

				<div className='rangeslider-container'>
					<Slider
						min={1}
						max={10}
						step={1}
						value={horizontal}
						tooltip={false}
						labels={horizontalLabels}
						handleLabel={horizontal.toString()}
						onChange={handleChangeHorizontal}
					/>

					<div className="forfurther-point1"></div>
					<div className="forfurther-point2"></div>
				</div>
			</div>
		</div>
	);
}

export default ForFurther;