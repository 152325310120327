// React
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { perfume } from '../../utils/communication';
import Nav from "../partials/Nav";
import GetTrad from "../../utils/GetTrad";
import Explanations from "../Explanations/Explanations";
import ExplanationsSpec from "../Explanations/ExplanationsSpec";
import Step from "../partials/Step";
// CSS
import "./flavour.css"

function Step3(props) {
	const navigate = useNavigate();

	const { bottles, flavour, selection, bottle } = useLocation().state;

	const language = useSelector((state) => state.language.current);

	const [explanations, setExplanations] = useState(10);

	const [bottleLocal, setBottleLocal] = useState(bottle);

	const goDiscover = () => {
		//props.setTransition(1);

		setTimeout(() => {
			let bottleTmp
			if (bottles) {
				bottleTmp = bottles[0]
				for (let tmp of bottles) {
					if (tmp.score > bottleTmp.score) {
						bottleTmp = tmp;
					}
				}
			} else {
				bottleTmp = bottle;
			}

			navigate('/discover', { state: { bottle: bottleTmp } });
		}, 500);
	}

	useEffect(() => {
		props.setTransition(0);

		if (bottles) {
			let bottleTmp = bottles[0]
			for (let tmp of bottles) {
				if (tmp.score > bottleTmp.score) {
					bottleTmp = tmp;
				}
			}
			setBottleLocal(bottleTmp);
		} else {
			setBottleLocal(bottle);
		}
	}, []);

	useEffect(() => {
		if (explanations === 0) {
			let elem = document.getElementById('bottleVideo');

			elem.currentTime = 0;
			elem.style.display = "block";
			elem.style.objectFit = "fill";
			elem.play();

			elem.onclick = () => {
				elem.style.display = "none";
				props.setSmell({ time: 2, next: goDiscover() });

				perfume(bottleLocal.scent);
			}

			elem.onended = () => {
				elem.style.display = "none";
				props.setSmell({ time: 2, next: goDiscover() });

				perfume(bottleLocal.scent);
			}
		}
	}, [explanations]);

	let resize = () => {
		let videoContainerElem = document.getElementById('bottleVideoContainer');
		let videoElem = document.getElementById('bottleVideo');

		if (videoContainerElem && videoElem) {
			let width = videoContainerElem.offsetWidth;
			let height = videoContainerElem.offsetHeight;

			if (width / height >= 2736 / 1824) {
				videoElem.style.width = "100%";
				videoElem.style.height = "";

				videoElem.style.left = "0%";
				videoElem.style.top = "50%";
				videoElem.style.transform = ("translateY(-50%)");
			} else {
				videoElem.style.width = "";
				videoElem.style.height = "100%";

				videoElem.style.left = "50%";
				videoElem.style.top = "0%";
				videoElem.style.transform = ("translateX(-50%)");
			}
		}
	}

	useEffect(() => {
		resize();
	});

	useEffect(() => {
		window.addEventListener("resize", () => {
			resize();
		});
		return () => {
			window.addEventListener("resize", () => {
				resize();
			});
		}
	}, []);

	let dataExplanations = null;
	if (flavour && selection) {
		dataExplanations = {
			time: 10,
			desc: [
				"explanationsFlavour-desc2-2",
			],
			flavour: flavour,
			selection: selection
		};
		// dataExplanations = {
		// 	time: 10,
		// 	desc: [
		// 		"explanationsFlavour-desc2-0",
		// 		//flavour ? flavour.desc : "explanationsFlavour-desc2-1",
		// 		"explanationsFlavour-desc2-1",
		// 		"explanationsFlavour-desc2-2",
		// 		"explanationsFlavour-desc2-3",
		// 		"explanationsFlavour-desc2-4",
		// 	],
		// 	obj: [
		// 		(<div className="explanations-watch">
		// 			<div className="explanations-watchImg"></div>
		// 			<div className="explanations-watchContent">
		// 				<GetTrad id="explanations-watch" lang={language} upperCase />
		// 			</div>
		// 		</div>),
		// 		(<div className="explanations-and">{'&'}</div>),
		// 		(<div className="explanations-smell">
		// 			<div className="explanations-smellImg"></div>
		// 			<div className="explanations-smellContent">
		// 				<GetTrad id="explanations-smell" lang={language} upperCase />
		// 			</div>
		// 		</div>)
		// 	],
		// 	flavour: flavour,
		// 	selection: selection
		// };
	} else {
		if (explanations !== 0) {
			setExplanations(0);
		}
	}

	return (
		<div className="step3">
			<Nav setTransition={props.setTransition} />
			<Step step={2} />
			{/* {explanations > 0 && <Explanations step={3} setExplanations={setExplanations} data={dataExplanations} />} */}
			{dataExplanations && bottleLocal && explanations > 0 && <ExplanationsSpec step={3} setExplanations={setExplanations} data={dataExplanations} bottleName={bottleLocal.name} />}

			{bottleLocal && bottleLocal.bottleVideo && <div id="bottleVideoContainer" className="bottleVideoContainer"><video id={'bottleVideo'} className="bottleVideo" src={bottleLocal.bottleVideo}> </video></div>}
			<div className="step3-skip"><div className='arrow'></div><div className="step3-skip-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

export default Step3;