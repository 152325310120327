export default new Map([
    ["tasteWhiteHoney", {
        scent: 11,
        id: "tasteWhiteHoney",
        desc: "desc-tasteWhiteHoney",
        css: "smell-whiteHoney",
        img: "smell_whiteHoney",
        bgImg: 'url("/assets/backgrounds/smells/smell_whiteHoney.jpg")',
        bottles: [
            "vsopPrivilege"
        ],
    }],
    ["tasteOrangeBlossom", {
        scent: 10,
        id: "tasteOrangeBlossom",
        desc: "desc-tasteOrangeBlossom",
        css: "smell-orangeBlossom",
        img: "smell_orangeBlossom",
        bgImg: 'url("/assets/backgrounds/smells/smell_orangeBlossom.jpg")',
        bottles: [
            "vsopPrivilege"
        ],
    }],
    ["tasteCandiedFruit", {
        scent: 20,
        id: "tasteCandiedFruit",
        desc: "desc-tasteCandiedFruit",
        css: "smell-candiedFruit",
        img: "smell_candiedFruit",
        turn: true,
        bgImg: 'url("/assets/backgrounds/smells/smell_mango.jpg")',
        bottles: [
            "vsopPrivilege"
        ],
    }],
    ["tasteFruityApricot", {
        scent: 15,
        id: "tasteFruityApricot",
        desc: "desc-tasteFruityApricot",
        css: "smell-fruityApricot",
        img: "smell_fruityApricot",
        bgImg: 'url("/assets/backgrounds/smells/smell_fruityApricot.jpg")',
        bottles: [
            "jamesHennessy"
        ],
    }],
    ["tasteVanillaPod", {
        scent: 12,
        id: "tasteVanillaPod",
        desc: "desc-tasteVanillaPod",
        css: "smell-vanillaPod",
        img: "smell_vanillaPod",
        bgImg: 'url("/assets/backgrounds/smells/smell_vanillaPod.jpg")',
        bottles: [
            "jamesHennessy"
        ],
    }],
    ["tasteChocolate", {
        scent: 16,
        id: "tasteChocolate",
        desc: "desc-tasteChocolate",
        css: "smell-chocolate",
        img: "smell_chocolate",
        bgImg: 'url("/assets/backgrounds/smells/smell_chocolate.jpg")',
        bottles: [
            "hennessyXO"
        ],
    }],
    ["tasteOakwood", {
        scent: 9,
        id: "tasteOakwood",
        desc: "desc-tasteOakwood",
        css: "smell-oakwood",
        img: "smell_oakwood",
        bgImg: 'url("/assets/backgrounds/smells/smell_oakwood.jpg")',
        bottles: [
            "hennessyXO"
        ],
    }],
    ["tasteBlackPepper", {
        scent: 20, //dfljgfdljgdklfgldfkjg
        id: "tasteBlackPepper",
        desc: "desc-tasteBlackPepper",
        css: "smell-blackPepper",
        img: "smell_blackPepper",
        bgImg: 'url("/assets/backgrounds/smells/smell_blackPepper.jpg")',
        bottles: [
            "hennessyXO"
        ],
    }],
    ["tasteSaltedButterCaramel", {
        scent: 13,
        id: "tasteSaltedButterCaramel",
        desc: "desc-tasteSaltedButterCaramel",
        css: "smell-saltedButterCaramel4",
        img: "smell_saltedButterCaramel",
        bgImg: 'url("/assets/backgrounds/smells/smell_saltedButterCaramel.jpg")',
        bottles: [
            "hennessyXXO"
        ]
    }],
    ["tasteSandalwood", {
        scent: 14,
        id: "tasteSandalwood",
        desc: "desc-tasteSandalwood",
        css: "smell-sandalwood3",
        img: "smell_sandalwood",
        bgImg: 'url("/assets/backgrounds/smells/smell_sandalwood.jpg")',
        bottles: [
            "hennessyXXO"
        ],
    }],
    ["tasteCandiedOrange", {
        scent: 20, //fd,lgdkfljgldkfjg
        id: "tasteCandiedOrange",
        desc: "desc-tasteCandiedOrange",
        css: "smell-candiedOrange",
        img: "smell_candiedOrange",
        bgImg: 'url("/assets/backgrounds/smells/smell_mango.jpg")',
        bottles: [
            "hennessyXXO"
        ],
    }],
    ["tasteBlondTobacco", {
        scent: 8,
        id: "tasteBlondTobacco",
        desc: "desc-tasteBlondTobacco",
        css: "smell-tobacco",
        img: "smell_tobacco",
        bgImg: 'url("/assets/backgrounds/smells/smell_tobacco.jpg")',
        bottles: [
            "paradis"
        ],
    }],
    ["tasteMango", {
        scent: 7, //fdlgdkfljgldkfjg
        id: "tasteMango",
        desc: "desc-tasteMango",
        css: "smell-mango",
        img: "smell_mango",
        bgImg: 'url("/assets/backgrounds/smells/smell_mango.jpg")',
        bottles: [
            "paradis"
        ],
    }],
    ["tasteTonkaBean", {
        scent: 20, //djfhgkdg
        id: "tasteTonkaBean",
        desc: "desc-tasteTonkaBean",
        css: "smell-tonkaBean",
        img: "smell_tonkaBean",
        bgImg: 'url("/assets/backgrounds/smells/smell_mango.jpg")',
        bottles: [
            "jamesHennessy"
        ],
    }],
])