// React
import { useEffect } from "react";
import { useSelector } from 'react-redux'
// Utils
import { default as GetTrad, getTrad } from "../../utils/GetTrad";
// CSS
import "./explanations.css"

function Explanations(props) {
	const language = useSelector((state) => state.language.current);

	useEffect(() => {
		/*setTimeout(() => {
			props.setExplanations(0);
		}, props.data ? props.data.time * 1000 : 5000)*/
	}, []);

	return (
		<div className="explanations">
			<div className="explanations-content">
				<div className="explanation-step-container">
					<div className="explanations-step-chip"> </div>
					<div className="explanations-step">
						<GetTrad id='explanations-step' lang={language} upperCase />{" 0" + (props.step) + " / 03"}
					</div>
				</div>
				<div className="explanations-desc">
					{props.data.desc.map((elem, index) => {
						let data = null;
						let uppercase = true;
						if (elem === "explanationsFlavour-desc2-3") {
							return (<div key={elem + '_' + index} className="explanations-desc-line">
								<div className="step3-explanations-elemLeft" style={{ backgroundImage: `url(/assets/backgrounds/step3-preview/${props.data.flavour.id}.jpg)` }}>
									<div className='step3-explanations-content'><GetTrad id={'desc-' + props.data.flavour.id} lang={language} upperCase /></div>
								</div>
								{props.data.selection.map((elem, id) => {
									return (
										<div key={elem + '_' + index + '_' + id} className="step3-explanations-elemRight" style={{ backgroundImage: `url(/assets/backgrounds/step3-preview/${elem}.jpg)` }}>
											<div className='step3-explanations-content'><GetTrad id={'desc-' + elem} lang={language} upperCase /></div>
										</div>
									)
								})}<br />
							</div>)
						}

						if (elem === "explanationsCognac-desc1-1" && (language === "fr" || language === "zh-hans" || language === "zh-hant")) {
							return;
						}

						if (props.data.bottle) {
							data = [];
							data.push(props.data.bottle)
						}

						return (<div key={elem + '_' + index} className="explanations-desc-line">
							<GetTrad id={elem} lang={language} upperCase={uppercase} data={data} /><br />
						</div>)
					})}
					<div className="explanations-objs">
						{props.data.obj.map((elem, index) => (<span key={elem + '_' + index}>{elem}</span>))}
					</div>
				</div>
			</div>
			<div className="explanations-next-container" onClick={() => props.setExplanations(0)}></div>
			<div className="explanations-next"><div className='arrow'></div><div className="explanations-next-label"><GetTrad id='explanations-next' lang={language} upperCase /></div></div>
		</div>
	);
}

export default Explanations;