// React
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import { Nav, Step } from '../partials/index';
// Utils
import GetTrad from '../../utils/GetTrad';
// CSS
import './cognac.css';
// Data
import { default as bottlesList } from '../../data/bottlesList';

function Step1(props) {
	const navigate = useNavigate();

	const language = useSelector((state) => state.language.current);

	const select = (event, value) => {
		event.stopPropagation();

		for (let i = 0; i < bottlesList.length; i++) {
			let elem = document.getElementById('select_' + i);

			if (elem) {
				if (i !== value) {
					elem.style.filter = "brightness(50%) grayscale(70%)";
					elem.style.pointerEvents = "none";
				} else {
					elem.style.filter = "";
					elem.style.pointerEvents = "all";
				}
			}
		}

		for (let i = 0; i < bottlesList.length; i++) {
			let elemLikeButton = document.getElementById('selectButton_' + i);
			let elemLikeImg = document.getElementById('selectImg_' + i);
			let elemLikeContent = document.getElementById('selectContent_' + i);

			if (elemLikeButton && elemLikeImg && elemLikeContent) {
				if (i === value) {
					elemLikeButton.style.backgroundColor = "white";
					elemLikeImg.style.filter = "none";
					elemLikeContent.style.color = "black";
				} else {
					elemLikeButton.style.backgroundColor = "";
					elemLikeImg.style.filter = "invert(100%)";
					elemLikeContent.style.color = "";
				}
			}
		}

		setTimeout(() => {
			props.setTransition(1);

			setTimeout(() => {
				navigate('/cognac2', { state: { bottle: bottlesList[value] } });
			}, 500);
		}, 1000);
	}

	useEffect(() => {
		props.setTransition(0);
	}, []);

	return (
		<div className="c-step1">
			<Nav setTransition={props.setTransition} />
			<Step step={0} />

			<div className="c-step1-selectBottle">
				<div className="c-step1-selectBottleContent">
					<div className="c-step1-chip"> </div>
					<div className="c-step1-step">
						<GetTrad id='explanations-step' lang={language} upperCase />{" 01"}
					</div>
					<br /><GetTrad id="c-step1-selectBottle" lang={language} upperCase />
				</div>
			</div>

			{bottlesList.map((elem, i) => (
				<div id={'select_' + i} key={elem.id} className={elem.css} style={{ backgroundImage: elem.cognacsImg }}>
					{/* <div className="c-step1-bottleImg" style={{ backgroundImage: elem.bottleImg }}></div> */}
					<div className="c-step1-bottle">
						{elem.name.toUpperCase()}
						<br /><br />
						<div id={'selectButton_' + i} className="c-step1-select" onClick={(e) => select(e, i)}>
							<div className={"c-step1-selectContainer"}>
								<div id={'selectImg_' + i} className="c-step1-selectImg"></div>
								<div id={'selectContent_' + i} className="c-step1-selectContent">
									<GetTrad id="step1-select" lang={language} upperCase />
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default Step1;