// React
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Actions
import { setLanguageCurrent } from '../../actions/language';
import { endSession, initSession } from '../../utils/communication';
// CSS
import "./language.css";

function Language(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const lang = [
		{ id: "fr", value: "Français" },
		{ id: "en", value: "English" },
		{ id: "zh-hans", value: "简体中文" },
		{ id: "zh-hant", value: "繁體中文" },
	]

	const selectLang = (id) => {
		endSession();
		setTimeout(() => {
			initSession();
		}, 1000);

		dispatch(setLanguageCurrent(id));

		props.setTransition(1);

		setTimeout(() => {
			navigate('/home');
		}, 500);
	}

	useEffect(() => {
		props.setTransition(0);
	}, [])

	return (
		<div className="language">
			<div className="language-logo"></div>
			<div className="language-langContainer">
				{lang.map((elem, index) => (
					<div className="language-langButton" key={'language-langButton-' + elem.id} onClick={() => selectLang(elem.id)}>
						<div className="language-langButtonContent">{elem.value.toUpperCase()}</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Language;